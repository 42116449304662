<template>
  <LayoutVendor>
    <template v-slot:title>MATERIALS AND SUPPLIES</template>
    <ProductForm></ProductForm>
  </LayoutVendor>
</template>

<script>
import ProductForm from '@/components/Partial/Products/ProductForm/ProductForm.vue';
import LayoutVendor from '@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
export default {
  name: 'VendorProductAdd',

  components: {
    ProductForm,
    LayoutVendor,
  },
};
</script>
